var a = document.querySelector('.funny-background');
var b = document.querySelector('.about');
var c = a.getContext('2d');
var p = [];
var s = 50;
var z = 0;
var render = function() {
    window.requestAnimationFrame(render);
    z++;
    if (z > 10){
        z = 0;

        s = p.length;
        while(s--){
            var q = p[s];

            //pick random color
            const color = Math.random() * 0.5 + 0.5;
            // pick random width
            const width = Math.random() * 0.9 + 1;

            c.beginPath();
            c.lineWidth = 1;
            if (s % 10 === 0) {
              c.strokeStyle = 'rgba(230,160,160,' + color + ')';
            } else {
              c.strokeStyle = 'rgba(230,230,230,' + color + ')';
            }
            c.lineWidth = width;
            c.moveTo(q.x,q.y);
            q.vx += q.ax * 1;
            q.vy += q.ay * 1;
            q.x += q.vx * 1;
            q.y += q.vy * 1;
            c.lineTo(q.x,q.y);
            c.stroke();
            if(q.x < 0 || q.x > a.width) {
                q.vx = -q.vx;
                q.ax = -q.ax;
            }
            if(q.y < 0 || q.y > a.height) {
                q.vy = -q.vy;
                q.ay = -q.ay;
            }
            q.ax = 3 - 6 * Math.random() - q.vx * 0.1;
            q.ay = 3 - 6 * Math.random() - q.vy * 0.1;
        }
    }
};

var d = 0;
var e = Math.min(600, document.body.clientWidth - 14 );
var f = document.querySelector('.simple-slider-slide');
var i = 0;
document.querySelector('.simple-slider-left').onclick = function() {
    d--;
    if (d < 0) d = 6;
    f.style.marginLeft = -(e * d) + 'px';
    clearInterval(i);
    i = setInterval(g, 3000);
};
var g = function() {
    d = (d + 1) % 6;
    f.style.marginLeft = -(e * d) + 'px';
    clearInterval(i);
    i = setInterval(g, 3000);
};
document.querySelector('.simple-slider-right').onclick = g;
i = setInterval(g, 3000);

var resize = function() {
    const bb = a.getBoundingClientRect();
    console.info(bb)
    if(bb) {
     a.width = bb.width;
     a.height = bb.height
    }
    c.fillStyle = "#FFF";
    c.fillRect(0,0,a.width,a.height);
    e = Math.min(600, document.body.clientWidth - 14 );
    f.style.marginLeft = -(e * d) + 'px';
};
window.onresize = resize;

var h = 0;
var interval = setInterval(function(){
    if(b.offsetHeight !== h) {
        resize();
        while(s--){
            p.push({
                x: a.width * Math.random(),
                y: a.height * Math.random(),
                vx: 0,
                vy: 0,
                ax: 0,
                ay: 0
            });
        }
        //window.requestAnimationFrame(render);
        clearInterval(interval);
        h = b.offsetHeight;
    }
}, 50);
render()